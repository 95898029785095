<template lang="pug">
  b-row.mb-5
    b-col

      b-card.manager-contacts-link_networks-list.card-white.card-no-gutters.shadow(no-body)
        b-card-header.bg-transparent.border-0
          wc-tables-header(
            :title="$t('activerecord.models.contact/link_network.other')"
            :action-new='policies.new'
            :route-new="{ name: 'manager-contacts-link_networks-new' }"
            @click-data-clean-fetch='dataCleanFetch'
            @click-data-fetch='dataFetch')

        b-card-body
          b-row
            b-col
              wc-tables-filter(
                :filter.sync='filter'
                :p-page.sync='pagination.pPage'
                :p-page-options='pagination.pPageOptions'
                @submit='dataFetch'
                @submit-clear='filterClear')

          b-row
            b-col
              transition(name='fade')
                wc-tables-wrapper-default(
                  :ref='wns'
                  :items='items'
                  :fields='fields'
                  :sort-by.sync='sorting.sortBy'
                  :sort-desc.sync='sorting.sortDesc'
                  :is-loading='isLoading'
                  :action-delete='actionDestroy'
                  @row-clicked='rowClicked'
                  @row-delete='rowDelete'
                  @sort-changed='sortingChanged'
                  attr-active)

        b-card-footer.border-0.bg-white
          wc-tables-footer(
            :total-items='pagination.totalItems'
            :p-page='pagination.pPage'
            :c-page.sync='pagination.cPage')

        loading(
          :active.sync='isLoading'
          :can-cancel-esc='true'
          :on-cancel='onCancel')
</template>

<script>
import Table from '@common/table'
import apiLinkNetworks from '@services/api/manager/globals/contacts/link_networks'

export default {
  name: 'manager-contacts-link_networks-list',
  mixins: [Table],
  props: {
    wns: {
      type: String,
      default: 'cln',
    },
  },
  methods: {
    routeParams(item) {
      return {
        edit: [
          {
            name: 'manager-contacts-link_networks-edit',
            params: { link_network_id: item.attributes.sid },
          },
        ],
      }
    },
  },
  data() {
    return {
      apiBase: apiLinkNetworks,
      actionRowClicked: 'edit',
      fields: [
        {
          key: 'id',
          label: this.$t('activerecord.attributes.shared.id'),
          sortable: true,
          attr: 'contact_link_networks.id',
          class: 'dots-100 h-100',
          noClick: true,
        },
        {
          key: 'attributes.name',
          label: this.$t('activerecord.attributes.shared.name'),
          sortable: true,
          attr: 'contact_link_networks.name',
        },
        {
          key: 'attributes.discarded_at',
          label: this.$t('activerecord.attributes.shared.discarded_at'),
          sortable: true,
          attr: 'contact_link_networks.discarded_at',
        },
        {
          key: 'attributes.updated_at',
          label: this.$t('activerecord.attributes.shared.updated_at'),
          sortable: true,
          attr: 'contact_link_networks.updated_at',
        },
        {
          key: 'attributes.created_at',
          label: this.$t('activerecord.attributes.shared.created_at'),
          sortable: true,
          attr: 'contact_link_networks.created_at',
        },
        {
          key: 'delete',
          label: this.$t('shared.actions.delete'),
          class: 'text-right',
        },
      ],
    }
  },
}
</script>
