import api from '@services/api'

export default {
  list(axios, params, query, config) {
    return api.get(axios, '/manager/contacts/link_networks', params, query, config)
  },

  get(axios, params, query, config) {
    return api.get(axios, '/manager/contacts/link_networks/:id', params, query, config)
  },

  create(axios, params, payload, config) {
    return api.post(axios, '/manager/contacts/link_networks', params, payload, config)
  },

  update(axios, params, payload, config) {
    return api.update(axios, '/manager/contacts/link_networks/:id', params, payload, config)
  },

  destroy(axios, params, config) {
    return api.delete(axios, '/manager/contacts/link_networks/:id', params, config)
  },
}
